@import "../node_modules/bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

body {
  font-family: 'Inter', sans-serif;

  h1 {
    font-weight: 700;
    white-space: nowrap;
    font-size: 48pt;
    @include media-breakpoint-up(sm) { 
      font-size: 68pt;
    }
    @include media-breakpoint-up(lg) { 
      font-size: 72pt;
    }
  }
  background-color: #000;
}

#steamy-oven {
  position: absolute;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.main-container {
  min-height: 100vh;
  position: relative;
  z-index: 10;
  padding-top: 35vh;
  color: #fff;
  a {
    color: #fff;
  }
}

.foot {
  padding-bottom: 2rem;
}